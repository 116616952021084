import axios from "@/api/axios";
import store from "@/store";
import _ from "lodash";
import qs from "qs";

// 브랜드정보보기
const get_brandview = async (result) => {
  let finalData = {
    code: 200,
    message: "Ok",
    brandview: {
      email: "",
    },
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/membership/brandview",
    headers: { Authorization: "Bearer " + token },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_brandview", err);
    result(false, err);
  }
};

const sendEmail = async (sendData, result) => {
  let finalData = {
    code: 0,
    message: "",
  };

  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/membership/membermailsend",
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data: qs.stringify(sendData),
    //params: {}
  };

  await axios(options).then((response) => {
    if (response.data.code === 200) {
      finalData = response.data;
      result(true, finalData);
    } else {
      result(false, response.data);
    }
  });
};

// 매장찾기 리스트
const get_memberlist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    findex: "FU.reg_date",    
    memberlist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/membership/memberlist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      findex: param.findex,        
      pagesize: param.pagesize,       
      pageno: param.pageno,      
      brandno: param.brandno, 
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_memberlist", err);
    result(false, err);
  }
};

// 회원정보보기
const get_playerinfo = async (memberno, result) => {
  let finalData = {
    code: "",
    message: "",
    playerbasicinfo: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/membership/memberview/" + memberno, 
    // params: {
    //   memberno: memberno,
    // },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_playerinfo", err);
    result(false, err);
  }
};

const set_memberwrite = async (sendData, result) => {
  let finalData = {
    code: 0,
    message: "",
  };

  // alert(sendData.memberno);
  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/membership/memberwrite",
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data: qs.stringify(sendData),
    //params: {}
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("set_memberwrite", err);
    result(false, err);
  }
};

export default {
  get_brandview,
  sendEmail,
  get_memberlist,
  get_playerinfo,
  set_memberwrite,
};
