<template>
  <!-- div id="wrap" :class="{confirmp: name === 'ConfirmPassword'}" -->
  <div id="wrap">
    <div
      :id="wrapId"
      :class="{
        login:
          name === 'Login' ||
          name === 'Find_Id_Pwd' ||
          name === 'ConfirmPassword' ||
          name === 'Terms' ||
          name === 'Privacy',
        guide: name === 'JoinTournament',
        minigame: name === 'JoinMiniGame',
        terms: name === 'Terms',
        privacy: name === 'Privacy',
      }"
    >
      <Header />
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "DefaultLayout",
  components: {
    Header,
    Footer,
  },
  computed: {
    wrapId() {
      return this.$route.meta.wrapId || "sub";
    },
    name: function () {
      return this.$route.name ? this.$route.name : "";
    },
  },
};
</script>